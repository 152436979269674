import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'
import { Link, useParams } from 'react-router-dom';
import OfferModal from "../components/OfferModal"; 
import axios from 'axios';
import { useAuth } from "../context/AuthContext";
import config from '../config';

const fetchContentApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-content/DetailPage');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


    const fetchPostData = async (url) => {
      try {
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

export default function Postadd() {

    const [content, setContent] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
        };

        fetchData();
    },[])
    

    const [showModal, setShowModal] = useState(false);
    const { authStatus } = useAuth();
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const {id} = useParams();

    const [post, setPost] = useState([]);
    const [info, setInfo] = useState([]);
    const [images, setImages] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            const postData = await fetchPostData(config.apiUrl+'get-post-detail/'+id);
            if (postData && postData.data.detail) {
                setPost(postData.data.detail);
            }
            if (postData && postData.data.additional_info) {
                setInfo(postData.data.additional_info);
            }
            if (postData && postData.data.images) {
                setImages(postData.data.images);
            }
        };

        fetchData();
    },[])

    const handleMakeOfferClick = () => {
        if (authStatus === false) {
          window.location.href = config.adminbaseUrl; 

        } else if (authStatus === true) {
          
          setShowModal(true);
        }
      };
    const basePrice = post?.price;

    
    
    

    const [mainSlider, setMainSlider] = useState(null);
    const [navSlider, setNavSlider] = useState(null);

    var products = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        asNavFor: navSlider,
        slidesToScroll: 1,
    };
    var products_sml = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        focusOnSelect: true,
        asNavFor: mainSlider,
        slidesToScroll: 1,
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString); 
      const year = date.getFullYear().toString(); 
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0'); 

      return `${year}-${month}-${day}`;
    };

    const formattedDate = formatDate(post?.created_at);
    let transmissionElement = null;
    let makeElement = null;
    let modelElement = null;
    let yearElement = null;
    let enginElement = null;
    let bodyElement = null;
    let enginTypeElement = null;


    let seatsElement = null;
    let colorElement = null;
    

    if (info && info.transmission) {
        transmissionElement = (
          <li>
            <a href="#">
              <i className="icon-gear1" />
              Transmission <span>{info.transmission}</span>
            </a>
          </li>
        );
      }
      if (info && info.make) {
        makeElement = (
        <li><a href="#"><i className="fa-solid fa-car" />Make <span>{info.make}</span></a></li>
        );
      }
      if (info && info.model) {
        modelElement = (
        <li><a href="#"><i className="fa-solid fa-car" />Model <span>{info.model}</span></a></li>
        );
      }
      if (info && info.year) {
        yearElement = (
        <li><a href="#"><i className="icon-calendar-check-o" />Year <span>{info.year}</span></a></li>
        );
      }

      if (info && info.year) {
        yearElement = (
        <li><a href="#"><i className="icon-calendar-check-o" />Year <span>{info.year}</span></a></li>
        );
      }
      if (info && info.engine_size) {
        enginElement = (
        <li><a href="#"><i className="icon-engine" />Engine Size <span>{info.engine_size}</span></a></li>
        );
      }

      if (info && info.body) {
        bodyElement = (
        <li><a href="#"><i className="icon-car_3" />Body Type <span>{info.body}</span></a></li>
        );
      }

      if (info && info.transmission) {
        enginTypeElement = (
            <li><a href="#"><i className="icon-engine" />Engine Type <span>{info.transmission}</span></a></li>
        );
      }
      if (info && info.seats) {
        seatsElement = (
            <li><a href="#"><i className="icon-seat" />Seat <span>{info.seats}</span></a></li>
        );
      }
      if (info && info.color) {
        colorElement = (
            <li><a href="#"><i className="icon-color_plate" />Color <span>{info.color}</span></a></li>
        );
      }
      

      


    return (

        <div>
            
            <section className="product_details_area p_100">
                <div className="container">
                    <div className="row product_details_inner">
                        <div className="col-lg-8">
                            <div className="product_details_left">
                                <div className="price_title d-flex justify-content-between">
                                    <div className="left">
                                        <h3>{post?.post_title}</h3>
                                        <div className="d-flex">
                                            <div className="star-rating" />
                                            
                                            </div>
                                    </div>
                                    <div className="right">
                                        <h4>£{post?.price}</h4>
                                    </div>
                                </div>
                                <div className="product_info">
                                    <a className="popup-with-zoom-anim" href="#getinfoPopup"><i className="icon-info1" />Request More Info</a>

                                    <a className="popup-with-zoom-anim" href="#bookPopup"><i className="icon-steering" />Book a Test Drive</a>
                                    <a href="#"><i className="icon-share1" />Share this Car</a>
                                </div>
                                <div className="product_d_slider">
                                    <div className="product_main_slider">
                                        <Slider {...products} ref={(slider) => setMainSlider(slider)}>
                                        {images.length > 0 ? (
                                            images.map((image) => (
                                            <div className="item">

                                                <img src={`${config.adminbaseUrl}${image.image}`} alt />
                                            </div>
                                            ))): (
                                                <div className="loader-in">Loading..</div>
                                            )}
                                        </Slider>
                                    </div>
                                    <div className="product_nav_slider">
                                        <Slider {...products_sml} ref={(slider) => setNavSlider(slider)}>
                                        {images.length > 0 ? (
                                            images.map((image) => (

                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src={`${config.adminbaseUrl}${image.image}`} alt />
                                                </div>
                                            </div>
                                            ))): (
                                                <div className="loader-in">Loading..</div>
                                        )}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">

                            <div className="product_list_right">
                                <a className="main_btn red popup-with-zoom-anim" href="#offerPopup" onClick={handleMakeOfferClick}><i className="fa-solid fa-comment-dollar"></i> Make an Offer Price</a>
                                <a className="main_btn red popup-with-zoom-anim" target="_blank" href={`${config.adminbaseUrl}user/chat-request/${post?.id}`}><i className="fas fa-comments"></i> Chat</a>
                                
                                <ul className="nav flex-column">
                                    <li><a href="#"><i className="icon-clock_2" />Date <span>{formattedDate}</span></a></li>
                                    {transmissionElement}
                                    {makeElement}
                                    {modelElement}
                                    {yearElement}
                                    {enginElement}
                                    {bodyElement}
                                    {enginTypeElement}
                                    {seatsElement}
                                    {colorElement}
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="product_overview_text">
                        <h4>Overview</h4>
                        
                        {post?.post_description}
                    </div>
                    <div className="specification_area">
                        <div className="single_b_title">
                            <h3>Specification</h3>
                        </div>
                        <div className="row">
                            
                            <div className="col-lg-8">
                                <div className="right_spec">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className="spec_information nice_scroll">
                                                <h4>Performance &amp; Efficiency</h4>
                                                <ul className="nav flex-column">
                                                {
                                                    info.make !== null ? <li>Make <span>{info.make}</span></li>  :  '-'
                                                }
                                                {
                                                    info.model !== null ? <li>Model <span>{info.model}</span></li>  :  '-'
                                                }
                                                {
                                                    info.year !== null ? <li>Year <span>{info.year}</span></li>  :  '-'
                                                }
                                                {
                                                    info.fuel !== null ? <li>Fuel <span>{info.fuel}</span></li>  :  '-'
                                                }
                                                {
                                                    info.transmission !== null ? <li>Transmission <span>{info.transmission}</span></li>  :  '-'
                                                }
                                                {
                                                    info.km_driven !== null ? <li>KM Driven <span>{info.km_driven}</span></li>  :  '-'
                                                }
                                                {
                                                    info.owners !== null ? <li>No. of Owners <span>{info.owners}</span></li>  :  '-'
                                                }
                                                {
                                                    info.carcondition !== null ? <li>Car Condition <span>{info.carcondition}</span></li>  :  '-'
                                                }
                                                {
                                                    info.drivetrain !== null ? <li>Drivetrain <span>{info.drivetrain}</span></li>  :  '-'
                                                }
                                                {
                                                    info.color !== null ? <li>Car Color <span>{info.color}</span></li>  :  '-'
                                                }
                                                {
                                                    info.interior_color !== null ? <li>Interior Color <span>{info.interior_color}</span></li>  :  '-'
                                                }
                                                {
                                                    info.seats !== null ? <li>Seats <span>{info.seats}</span></li>  :  '-'
                                                }
                                                {
                                                    info.number_of_cylinders !== null ? <li>Number Of Cylinders <span>{info.number_of_cylinders}</span></li>  :  '-'
                                                }
                                                {
                                                    info.registered_car !== null ? <li>Registered Car <span>{info.registered_car}</span></li>  :  '-'
                                                }
                                                {
                                                    info.horse_power !== null ? <li>Horse Power <span>{info.horse_power}</span></li>  :  '-'
                                                }
                                                {
                                                    info.engine_size !== null ? <li>Engine Size <span>{info.engine_size}</span></li>  :  '-'
                                                }

                                                
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>

        <OfferModal show={showModal} handleClose={handleCloseModal} basePrice={basePrice}  adId={post?.id}/>
        </div>
    )
}
