import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'
import { Link, useParams } from 'react-router-dom';
import OfferModal from "../components/OfferModal"; 
import axios from 'axios';
import { useAuth } from "../context/AuthContext";
import config from '../config';

    const fetchContentApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-content/CookiePolicyPage');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

function Cookie_policy() {
    const [content, setContent] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
        };

        fetchData();
    },[])

    return (
        <div>
            
            <section className="terms">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <div className="term-txt">
                                <h4>{content[1]?.title}</h4>
                                {renderHTML(content[1]?.details)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Cookie_policy
