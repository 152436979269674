import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import config from '../config';
export default function Advertisement() {

    useEffect(() => {
        $(document).ready(function () {
            var base_color = "rgb(230,230,230)";
            var active_color = "rgb(45 113 73)";
            var child = 1;
            var length = $(".stepsss").length - 1;
            $("#prev").addClass("disabled");
            $("#submit").addClass("disabled");
            $(".stepsss").not(".stepsss:nth-of-type(1)").hide();
            $(".stepsss").not(".stepsss:nth-of-type(1)").css('transform', 'translateX(100px)');
            var svgWidth = length * 200 + 24;
            $("#svg_wrap").html(
                '<svg version="1.1" id="svg_form_time" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 ' +
                svgWidth +
                ' 24" xml:space="preserve"></svg>'
            );
            function makeSVG(tag, attrs) {
                var el = document.createElementNS("http://www.w3.org/2000/svg", tag);
                for (var k in attrs) el.setAttribute(k, attrs[k]);
                return el;
            }
            let i = 0;
            for (i = 0; i < length; i++) {
                var positionX = 12 + i * 200;
                var rect = makeSVG("rect", { x: positionX, y: 9, width: 200, height: 6 });
                document.getElementById("svg_form_time").appendChild(rect);
                // <g><rect x="12" y="9" width="200" height="6"></rect></g>'
                var circle = makeSVG("circle", {
                    cx: positionX,
                    cy: 12,
                    r: 12,
                    width: positionX,
                    height: 6
                });
                document.getElementById("svg_form_time").appendChild(circle);
            }
            var circle = makeSVG("circle", {
                cx: positionX + 200,
                cy: 12,
                r: 12,
                width: positionX,
                height: 6
            });
            document.getElementById("svg_form_time").appendChild(circle);
            $('#svg_form_time rect').css('fill', base_color);
            $('#svg_form_time circle').css('fill', base_color);
            $("circle:nth-of-type(1)").css("fill", active_color);
            $(".button").click(function () {
                $("#svg_form_time rect").css("fill", active_color);
                $("#svg_form_time circle").css("fill", active_color);
                var id = $(this).attr("id");
                if (id == "next") {
                    $("#prev").removeClass("disabled");
                    if (child >= length) {
                        $(this).addClass("disabled");
                        $('#submit').removeClass("disabled");
                    }
                    if (child <= length) {
                        child++;
                    }
                } else if (id == "prev") {
                    $("#next").removeClass("disabled");
                    $('#submit').addClass("disabled");
                    if (child <= 2) {
                        $(this).addClass("disabled");
                    }
                    if (child > 1) {
                        child--;
                    }
                }
                var circle_child = child + 1;
                $("#svg_form_time rect:nth-of-type(n + " + child + ")").css(
                    "fill",
                    base_color
                );
                $("#svg_form_time circle:nth-of-type(n + " + circle_child + ")").css(
                    "fill",
                    base_color
                );
                var currentSection = $(".stepsss:nth-of-type(" + child + ")");
                currentSection.fadeIn();
                currentSection.css('transform', 'translateX(0)');
                currentSection.prevAll('.stepsss').css('transform', 'translateX(-100px)');
                currentSection.nextAll('.stepsss').css('transform', 'translateX(100px)');
                $('.stepsss').not(currentSection).hide();
            });

        });
    });

    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!image) return;
    };

    return (
        <div>
            
            <section className="advertisement">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-lg-8 col-12">
                            <div id="svg_wrap" />
                            <div>
                                <section className="stepsss">
                                    <div className="advertisement-txts">
                                        <h3>ADVERTISEMENT</h3>
                                        <h4>EIUSMOD TEMPOR INDIDUNT</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum</p>
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="text" placeholder="PRODUCT NAME" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <select>
                                                        <option>PRODUCT CATEGORY</option>
                                                        <option>PRODUCT CATEGORY</option>
                                                        <option>PRODUCT CATEGORY</option>
                                                        <option>PRODUCT CATEGORY</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="text" placeholder="PRICE" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="text" placeholder="CONDITION" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="text" placeholder="MODAL" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="text" placeholder="SPACIFICATION" />
                                                </div>
                                                <div className="col-md-12 col-lg-12 col-12">
                                                    <textarea placeholder='PRODUCT DESCRIPTION' />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </section>
                                <section className="stepsss">
                                    <div className="col-12 ">
                                        <form>
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <label>Ad Name *</label>
                                                    <input type="text" name="fname" placeholder="First Name" required />
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label> Price * ($)</label>
                                                    <input type="text" name="name" placeholder="number" required />
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label>Category *</label>
                                                    <input type="text" name="cname" placeholder="Company Name" required />
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label>Sub Category *</label>
                                                    <input type="text" name="cname" placeholder="Company Name" required />
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <div id="otherField" style={{ display: 'none' }}>
                                                        <label htmlFor="specificRole">Specifics:</label>
                                                        <input type="text" id="specificRole" name="specificRole" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-12">
                                                    <label>Brand *</label>
                                                    <input type="text" name="text" placeholder required />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </section>
                                <section className="stepsss">
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="login-mobile" className="font-weight-bold text-dark">Phone Number (  Hide in details )</label>
                                                    <div className="input-group input-group-sm">
                                                        <div className="iti iti--allow-dropdown iti--separate-dial-code"><div className="iti__flag-container"><div className="iti__selected-flag" role="combobox" aria-controls="iti-0__country-listbox" aria-owns="iti-0__country-listbox" aria-expanded="false" tabIndex={0} title="United States: +1" aria-activedescendant="iti-0__item-us-preferred"><div className="iti__flag iti__us" /><div className="iti__selected-dial-code">+1</div><div className="iti__arrow" /></div></div><input id="mobile" type="tel" name="mobile" className="form-control" autofocus required autoComplete="off" style={{ paddingLeft: 75 }} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="login-mobile" className="font-weight-bold text-dark">Backup phone Number</label>
                                                    <div className="input-group input-group-sm">
                                                        <div className="iti iti--allow-dropdown iti--separate-dial-code"><div className="iti__flag-container"><div className="iti__selected-flag" role="combobox" aria-controls="iti-0__country-listbox" aria-owns="iti-0__country-listbox" aria-expanded="false" tabIndex={0} title="United States: +1" aria-activedescendant="iti-0__item-us-preferred"><div className="iti__flag iti__us" /><div className="iti__selected-dial-code">+1</div><div className="iti__arrow" /></div></div><input id="mobile" type="tel" name="mobile" className="form-control" autofocus required autoComplete="off" style={{ paddingLeft: 75 }} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="login-mobile" className="font-weight-bold text-dark">Whatsapp Number (Get help)</label>
                                                    <div className="input-group input-group-sm">
                                                        <div className="iti iti--allow-dropdown iti--separate-dial-code"><div className="iti__flag-container"><div className="iti__selected-flag" role="combobox" aria-controls="iti-0__country-listbox" aria-owns="iti-0__country-listbox" aria-expanded="false" tabIndex={0} title="United States: +1" aria-activedescendant="iti-0__item-us-preferred"><div className="iti__flag iti__us" /><div className="iti__selected-dial-code">+1</div><div className="iti__arrow" /></div></div><input id="mobile" type="tel" name="mobile" className="form-control" autofocus required autoComplete="off" style={{ paddingLeft: 75 }} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12">
                                                <label>Location * </label>
                                                <input type="text" name="cname" placeholder="Enter City Name" required />
                                            </div>
                                        </div>
                                    </form>
                                    <div className="col-md-12 col-lg-12 col-12">
                                        <div className="map-go">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9582057.40657833!2d-15.009922759448491!3d54.102653628903326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2s!4v1728649917971!5m2!1sen!2s" width="100%" height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                                        </div>
                                    </div>
                                </section>
                                <section className="stepsss">
                                    <div id="summernote" />
                                    <form>
                                        <div className="col-12 col-sm-6 col-lg-12">
                                            <label>Feature * </label>
                                            <input type="text" name="cname" placeholder="Feature" required />
                                        </div>
                                    </form>
                                </section>
                            </div>
                            <div className="button" id="prev">← Previous</div>
                            <div className="button" id="next">Next →</div>
                            <div className="button" id="submit">Agree and send application</div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="uplod-img">
                                <div className="proof">
                                    <button className="selectImage" type="button" onClick={handleSubmit} disabled={!image}>
                                        <div className="imgArea" data-title>
                                            <input type="file" accept="image/*" onChange={handleImageUpload} />
                                            <img src={previewUrl ? previewUrl : 'images/upload.png'} alt className="uplod-img" />
                                        </div>
                                    </button></div>
                                <div className="uplod-sm-img">
                                    <ul>
                                        <li><img src="images/upload-sm.png" alt /></li>
                                        <li><img src="images/upload-sm.png" alt /></li>
                                        <li><img src="images/upload-sm.png" alt /></li>
                                        <li><img src="images/upload-sm.png" alt /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
