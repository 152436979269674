import React from 'react'
import config from '../config';
export default function Signup() {
    return (
        <div>
            
            <section className="signin_sec">
                <div className="container">
                    <div className="row align-items-center bg-from-clr">
                        <div className="col-lg-7">
                            <div className="social_btn">
                                <h3>SIGN UP</h3>
                            </div>
                            <form action="#" className="signup_form">
                                <div>
                                    <div className="input-icons">
                                        <i className="fa fa-user icon" aria-hidden="true" />
                                        <input className="input-field" type="text" placeholder="Your Name" />
                                        <i className="fa fa-envelope icon" aria-hidden="true" />
                                        <input className="input-field" type="email" placeholder="Your Email" />
                                        <i className="fas fa-lock icon" aria-hidden="true" />
                                        <input className="input-field" type="password" placeholder="Your Password" />
                                        <i className="fas fa-lock icon" aria-hidden="true" />
                                        <input className="input-field" type="password" placeholder="Confirm Password" />
                                    </div>
                                    <div className="btn-wrapper">
                                        <button className="btn-2" type="submit">Sign Up</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5">
                            <div className="login">
                                <h3>LOGIN</h3>
                                <form action="#" className="login_form">
                                    <div className="input-icons">
                                        <i className="fa fa-envelope icon" aria-hidden="true" />
                                        <input className="input-field" type="email" placeholder="Your Email" />
                                        <i className="fas fa-lock icon" aria-hidden="true" />
                                        <input className="input-field" type="password" placeholder="Your Password" />
                                        <div className="remeber">
                                            <input type="checkbox" />
                                            <label htmlFor="remeber">Remeber me</label>
                                        </div>
                                    </div>
                                    <div className="forgot_pass">
                                        <a href="#">Forgot Password?</a>
                                        <div className="btn-wrapper">
                                            <button className="btn-2" type="submit">Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
