import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { useSettings } from '../context/SettingsContext';
const fetchContentApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-content/ContactPage');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
export default function Contact() {
    const { settings, loading, error } = useSettings();
    const [content, setContent] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
        };

        fetchData();
    },[])


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [status, setStatus] = useState({ success: null, message: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiUrl}inquiry`, formData);
            setStatus({ success: true, message: 'Message sent successfully!' });
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            setStatus({ success: false, message: 'Failed to send message. Please try again.' });
        }
    };

    return (
        <div>
            
            <section className="contact-sec all-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-10 col-md-11 centerCol">
                            <div className="contact-box">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact-text">
                                            <h6>{content[1]?.title}</h6>
                                            {renderHTML(content[1]?.details)}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact-form">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="cont-input">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                placeholder="NAME"
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cont-input">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                placeholder="EMAIL ADDRESS"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cont-input">
                                                            <textarea
                                                                name="message"
                                                                cols={30}
                                                                rows={10}
                                                                placeholder="MESSAGE"
                                                                value={formData.message}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="contact-button">
                                                        <button type="submit" className="btn1 btn2">Submit Now</button>
                                                    </div>
                                                </div>
                                            </form>
                                            {status.message && (
                                                <p className={status.success ? 'success-message' : 'error-message'}>
                                                    {status.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-4">
                                        <ul className="contact-icons center-box">
                                            <li><img src="images/con-2.png" alt className="con-2" /></li>
                                            <li>
                                                <p>{settings?.address}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <ul className="contact-icons">
                                            <li><img src="images/con-3.png" alt className="con-3" /></li>
                                            <li>
                                                <a href="mailto:{settings?.email}">
                                                    <p>{settings?.email}</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3838.3127222869352!2d-115.20379999240218!3d36.13570091039572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c6acd8cf5203%3A0x353d189adea1fcb0!2sClark%20High%20School!5e0!3m2!1sen!2s!4v1685971844921!5m2!1sen!2s" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
