import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSettings } from '../context/SettingsContext';
import ProductSearch from '../components/ProductSearch';
import config from '../config';
import { useAuth } from "../context/AuthContext";





export default function Header() {

    const { authStatus } = useAuth();
    const { settings } = useSettings();

    const [isOpen, setIsOpen] = useState(false);
    const [searchP, setSearchP] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('sidenav-open');
        } else {
            document.body.classList.remove('sidenav-open');
        }
        return () => document.body.classList.remove('sidenav-open');
    }, [isOpen]);


    useEffect(() => {
        location.pathname == '/' ? setSearchP(true) : setSearchP(true);
    }, [location.pathname])


    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);

    // console.log(links);

    return (
        <div>
            <header>
                <div className="topSec wow fadeInLeft" data-wow-duration="2s">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="logo-img">
                                    <Link to="/"><img src={settings?.logo} alt="img" /></Link>
                                </div>
                            </div>
                            <div className="col-xs-8 col-sm-8 col-md-5 col-lg-5">
                                {!searchP && <ProductSearch />}
                            </div>
                            <div className="col-xs-8 col-sm-8 col-md-5 col-lg-5">
                                <div className="ankar">
                                    <ul>
                                        <li>
                                            <a href={`${config.adminbaseUrl}`}>
                                                <i className="far fa-signs-post" />

                                                <h5>Post Ad </h5>
                                            </a>
                                        </li>
                                        {
                                            (authStatus === false)?
                                            <li>
                                                <a href={`${config.adminbaseUrl}`}>
                                                    <i className="far fa-user" />
                                                    <h5>SIGN UP</h5>
                                                </a>
                                            </li>
                                            :
                                            <li>
                                                <a href={`${config.adminbaseUrl}`}>
                                                    <i className="far fa-user" />
                                                    <h5>My Account</h5>
                                                </a>
                                            </li>
                                        }
                                        
                                        <li>
                                            <div className="nav-head">
                                                <span onClick={openNav}>
                                                    <i className="fa-solid fa-bars" />
                                                </span>
                                                <div className="sidenav" style={{ transform: isOpen ? 'translate(0px, 0px)' : 'translate(110%, 0px)' }}>
                                                    <Link to="javascript:void(0)" className="closebtn" onClick={closeNav}>×</Link>
                                                    <Link to="/" onClick={closeNav}>HOME</Link>
                                                    <Link to="/products" onClick={closeNav}>PRODUCTS</Link>
                                                    <Link to={`${config.adminbaseUrl}`} onClick={closeNav}>ADVERTISEMENT</Link>
                                                    <Link to="/blogs" onClick={closeNav}>BLOGS</Link>
                                                    <Link to="/faq" onClick={closeNav}>FAQ's</Link>
                                                    <Link to="/contact" onClick={closeNav}> CONTACT US</Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {searchP && <div className="menuSec wow fadeInRight" data-wow-duration="2s">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-lg-6 col-sm-3 col-xs-12">
                                <h3 className='search-tile'>What are you looking for?</h3>
                                <ProductSearch />
                            </div>
                        </div>
                    </div>
                </div>}
            </header>
        </div >
    )
}
