import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import config from '../config';

const fetchCategoryData = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-categories-data');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchPostData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchBannersApi = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-banners');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchContentApi = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-content/Home Page');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchCategoryLinks = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-category-link-data');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
export default function Home() {


    const [content, setContent] = useState([]);
    const [category, setCategory] = useState([]);
    const [listGrid, setList] = useState(false);
    const [post, setPost] = useState([]);
    const [banners, setBanners] = useState([]);
    const [recentpost, setRecentPost] = useState([]);
    // const [category, setCategory] = useState([]);
    const [links, setCategoryLinks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            const categoryData = await fetchCategoryData();
            const postData = await fetchPostData(config.apiUrl + 'get-feature-post?limit=6&featured=1');
            const recentpostData = await fetchPostData(config.apiUrl + 'get-feature-post?limit=100&featured=1');
            // get-post?limit=100
            const bannersData = await fetchBannersApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }

            if (categoryData && categoryData.data && categoryData.data.length > 0) {
                setCategory(categoryData.data);
            }
            if (postData && postData.data && postData.data.length > 0) {
                setPost(postData.data);
            }
            if (recentpostData && recentpostData.data && recentpostData.data.length > 0) {
                setRecentPost(recentpostData.data);
            }
            if (bannersData && bannersData.data && bannersData.data.length > 0) {
                setBanners(bannersData);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const categoryData = await fetchCategoryData();
            if (categoryData && categoryData.data && categoryData.data.length > 0) {
                setCategory(categoryData.data);
            }
            const categoryList = await fetchCategoryLinks();
            if (categoryList && categoryList.data && categoryList.data.length > 0) {
                setCategoryLinks(categoryList.data);
            }
        };
        fetchData();
    }, [])


    const [hoveredIndex, setHoveredIndex] = useState(null);
    return (
        <div>
            <section className="Despired-car">
                <div className="container-fluid">
                    <div className="row">
                        <div className="row mt-5">
                            <div className="col-lg col-md-4 col-12 wow fadeInRight" data-wow-duration="2s">
                                <div className="cate_box_new">
                                    <div class="cate_box_new">
                                        {
                                            links.length > 0 && links.map((link, key) => {
                                                return <div class="nav-underlist" onMouseEnter={() => setHoveredIndex(key)} onMouseLeave={() => setHoveredIndex(null)}>
                                                    <h5><img src={`images/${link.parent[1]}`} />{link.parent[0]}<i class="fa-solid fa-chevron-right"></i></h5>
                                                    <div className={`nav-underlist_listt ${hoveredIndex === key ? "show" : ""}`}>
                                                        {
                                                            link.childs.map((ch) => {
                                                                return <Link to={`/products?category=${ch.id}`}>
                                                                    <img src={ch.photo ? `${config.adminbaseUrl}assets/images/${ch.photo}` : 'https://cdn-icons-png.flaticon.com/512/6535/6535109.png'} />
                                                                    {ch.name}
                                                                </Link>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '80%' }}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="new-good">
                                            <h4>Trending ads</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 text-end">
                                        <button className={`btn-drfgg ${!listGrid && 'active'}`} onClick={() => { setList(false) }}><i class="fa-solid fa-grid-2"></i></button>
                                        <button className={`btn-drfgg ${listGrid && 'active'}`} onClick={() => { setList(true) }}><i class="fa-solid fa-list"></i></button>
                                        {/* <Link to="/products" className="btn-1">MORE PRODUCTS</Link> */}
                                    </div>
                                    {recentpost.length > 0 ?
                                        <div className={`masonry-grid ${listGrid && 'list'}`}>
                                            {recentpost.map((postd) => (
                                                <div className="masonry-item" key={postd.id}>
                                                    <div className="main-car-bx">
                                                        <div className="car-img">
                                                            <Link to={`post-add/${postd.id}`}>
                                                                <img
                                                                    src={postd.image}
                                                                    alt="Post"
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = `https://placehold.co/50x50/EEE/31343C?text=${postd.post_title}`;
                                                                    }}
                                                                />
                                                                {postd.featured === 1 ? <img src="images/new.png" className="FeaturedPost" alt="Featured" /> : null}
                                                            </Link>
                                                        </div>
                                                        <div className="car-txt">
                                                            <h5>{postd.post_title}<span>{postd.type} - {postd.category}</span></h5>
                                                            <ul>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                            </ul>
                                                            <div className="check">
                                                                <i className="fas fa-check" />
                                                                <h4>AVAILABLE</h4>
                                                            </div>
                                                            <Link to={`post-add/${postd.id}`} className="btn-1">View Post</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div> : (
                                            <div className="loader-in">Loading..</div>
                                        )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >





            
        </div >
    )
}
