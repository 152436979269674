import React from 'react'

const Plans = () => {
    return (
        <section className="pricing-section">
            <div className="container">
                <div className="sec-title text-center">
                    <span className="title">Get plan</span>
                    <h2>Choose a Plan</h2>
                </div>
                <div className="outer-box">
                    <div className="row">
                        {/* Premium Plan */}
                        <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="inner-box">
                                <div className="icon-box">
                                    <div className="icon-outer"><i className="fas fa-crown" /></div>
                                </div>
                                <div className="price-box">
                                    <div className="title">Premium Plan</div>
                                    <h4 className="price">$199.99</h4>
                                </div>
                                <ul className="features">
                                    <li className="true">Premium Membership Badge</li>
                                    <li className="true">Up to 10 Premium/Featured Ads</li>
                                    <li className="true">Alert when 5 Ads or less remain</li>
                                    <li className="true">Ads expire in 1 month</li>
                                    <li className="true">Deleted 3 days after expiry</li>
                                    <li className="true">Real-time dashboard updates</li>
                                </ul>
                                <div className="btn-box">
                                    <a href="#" className="theme-btn">BUY Plan</a>
                                </div>
                            </div>
                        </div>
                        {/* Standard Plan */}
                        <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                            <div className="inner-box">
                                <div className="icon-box">
                                    <div className="icon-outer"><i className="fas fa-gem" /></div>
                                </div>
                                <div className="price-box">
                                    <div className="title">Standard Plan</div>
                                    <h4 className="price">$99.99</h4>
                                </div>
                                <ul className="features">
                                    <li className="true">Up to 2 Featured Ads</li>
                                    <li className="true">Alert when 2 Ads or less remain</li>
                                    <li className="true">Ads expire in 14 days</li>
                                    <li className="true">Deleted 3 days after expiry</li>
                                    <li className="true">Real-time dashboard updates</li>
                                </ul>
                                <div className="btn-box">
                                    <a href="#" className="theme-btn">BUY Plan</a>
                                </div>
                            </div>
                        </div>
                        {/* Basic Plan */}
                        <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
                            <div className="inner-box">
                                <div className="icon-box">
                                    <div className="icon-outer"><i className="fas fa-paper-plane" /></div>
                                </div>
                                <div className="price-box">
                                    <div className="title">Basic Plan</div>
                                    <h4 className="price">$35.99</h4>
                                </div>
                                <ul className="features">
                                    <li className="true">Ads expire in 14 days</li>
                                    <li className="true">Deleted 3 days after expiry</li>
                                    <li className="true">Real-time dashboard updates</li>
                                </ul>
                                <div className="btn-box">
                                    <a href="#" className="theme-btn">BUY Plan</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Plans
