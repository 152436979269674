import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'
import { Link, useParams } from 'react-router-dom';
import OfferModal from "../components/OfferModal"; 
import axios from 'axios';
import { useAuth } from "../context/AuthContext";
import config from '../config';

    const fetchContentApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-content/BlogPage');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const fetchBlogApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-blogs');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

function Blog() {

    const [content, setContent] = useState([]);
    const [blogs, setBlog] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            const blogsData = await fetchBlogApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
            if (blogsData && blogsData.data && blogsData.data.length > 0) {
                setBlog(blogsData.data);
            }
        };

        fetchData();
    },[])


    return (
        <div>
            
            <section className="blog_sec sec ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="heading text_center topbar">
                                <h2>{content[1]?.title}</h2>
                                {renderHTML(content[1]?.details)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {blogs.length > 0 ? (
                            blogs.map((blog,index)=>(
                                <div className="col-lg-4">
                                    <div className="blog_box">
                                        <div className="blog_box_image">
                                            <img src={`${config.adminbaseUrl}assets/images/${blog.photo}`} alt />
                                        </div>
                                        <div className="blog_box_text">
                                            <ul>
                                                <li>
                                                    <i className="fa-regular fa-user" />
                                                    <p>By Admin</p>
                                                </li>
                                            </ul>
                                            <h4>{blog.title}</h4>
                                            {renderHTML(blog.details.slice(0, 100))}
                                            <a href={`/blog-detail/${blog.id}`}>MOre Detail</a>
                                        </div>
                                    </div>
                                </div>
                            ))) : (
                                    <div className="loader-in">Loading..</div>
                        )}
                        
                        
                    </div>

                </div>
            </section>

        </div>
    )
}

export default Blog
