import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
const fetchContentApi = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-content/TermsPage');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
export default function Terms() {
    const [content, setContent] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
        };

        fetchData();
    }, [])
    return (
        <div>
            
            <section className="terms">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <div className="term-txt">
                                <h4>{content[1]?.title}</h4>
                                {renderHTML(content[1]?.details)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
