import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const fetchPostData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchCategoryData = async (id) => {
    try {
        const response = await axios.get(config.apiUrl + 'get-categories-data?id='+id);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchContentApi = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-content/ProductPage');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


export default function Products() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [post, setPost] = useState([]);
    const [category, setCategory] = useState([]);
    const [catSelected, setCatSelected] = useState(urlParams.has('category') ? parseInt(urlParams.get('category')) : null);
    const [location, setLocation] = useState(null);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');

    const [content, setContent] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
        };

        fetchData();
    }, [])

    useEffect(() => {
        setCatSelected(urlParams.has('category') ? parseInt(urlParams.get('category')) : null);
    }, [urlParams])

    useEffect(() => {
        const fetchData = async () => {
            const postData = await fetchPostData(config.apiUrl + 'get-post');
            if (postData && postData.data && postData.data.length > 0) {
                setPost(postData.data);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const categoryData = await fetchCategoryData(catSelected);
            if (categoryData && categoryData.data && categoryData.data.length > 0) {
                setCategory(categoryData.data);
            }
        };
        fetchData();
    }, []);

    const setCatFilter = (e, id) => {
        e.preventDefault();
        setCatSelected(id);
    };

    const handleMinPriceChange = (e) => setMinPrice(e.target.value);
    const handleMaxPriceChange = (e) => setMaxPrice(e.target.value);

    return (
        <div>
            
            <section className="product-inner-sec all-section">
                <div className="container-fluid themes-container">
                    <div className="row align-items-start">
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="cate-main-bxinn">
                                <h4>Categories</h4>
                                <ul>
                                    {category.length > 0 ? (
                                        category.map((categorys) => (
                                            <li key={categorys.id}>
                                                <Link
                                                    to={`/products?category=${categorys.id}`}
                                                    className={catSelected === categorys.id ? 'selected' : ''}
                                                >
                                                    {categorys.name}
                                                </Link>
                                            </li>
                                        ))
                                    ) : (
                                        <div>Loading...</div>
                                    )}
                                </ul>
                            </div>
                            <div className="loc">
                                <input
                                    type="text"
                                    placeholder="Location"
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Price, GH₵
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="price-renge">
                                                <div className="price-in">
                                                    <input
                                                        type="number"
                                                        placeholder="min"
                                                        onChange={handleMinPriceChange}
                                                    />
                                                    <input
                                                        type="number"
                                                        placeholder="max"
                                                        onChange={handleMaxPriceChange}
                                                    />
                                                </div>
                                                <ul>
                                                    <li><input type="radio" name="radio" onClick={() => { setMinPrice(0); setMaxPrice(200000) }} /><p>Under 200k</p></li>
                                                    <li><input type="radio" name="radio" onClick={() => { setMinPrice(0); setMaxPrice(300000) }} /><p>Under 300k</p></li>
                                                    <li><input type="radio" name="radio" onClick={() => { setMinPrice(0); setMaxPrice(400000) }} /><p>Under 400k</p></li>
                                                    <li><input type="radio" name="radio" onClick={() => { setMinPrice(0); setMaxPrice(500000) }} /><p>Under 500k</p></li>
                                                    <li><input type="radio" name="radio" onClick={() => { setMinPrice(500000); setMaxPrice(9999999999999) }} /><p>more than 500k</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                {post.length > 0 ?
                                    <div className="masonry-grid">
                                        {post
                                            .filter((p) =>
                                                catSelected ? catSelected === p.categorydata.id : p
                                            )
                                            .filter((p) =>
                                                location
                                                    ? p.city.toLowerCase().includes(location.toLowerCase()) ||
                                                    p.zip.toLowerCase().includes(location.toLowerCase()) ||
                                                    p.state.toLowerCase().includes(location.toLowerCase())
                                                    : p
                                            )
                                            .filter((p) => {
                                                const price = parseFloat(p.price);
                                                const min = parseFloat(minPrice);
                                                const max = parseFloat(maxPrice);
                                                if (!isNaN(min) && price < min) return false;
                                                if (!isNaN(max) && price > max) return false;
                                                return true;
                                            })
                                            .map((postd) => (
                                                <div className="masonry-item" key={postd.id}>
                                                    <div className="main-car-bx">
                                                        <div className="car-img">
                                                            <Link to={`post-add/${postd.id}`}>
                                                                <img
                                                                    src={postd.image}
                                                                    alt="Post"
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = `https://placehold.co/50x50/EEE/31343C?text=${postd.post_title}`;
                                                                    }}
                                                                />
                                                                {postd.featured === 1 ? <img src="images/new.png" className="FeaturedPost" alt="Featured" /> : null}
                                                            </Link>
                                                        </div>
                                                        <div className="car-txt">
                                                            <h5>{postd.post_title}<span>{postd.type} - {postd.category}</span></h5>
                                                            <ul>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                            </ul>
                                                            <div className="check">
                                                                <i className="fas fa-check" />
                                                                <h4>AVAILABLE</h4>
                                                            </div>
                                                            <Link to={`post-add/${postd.id}`} className="btn-1">View Post</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div> : (
                                        <div>Loading...</div>
                                    )}
                                <div className='notfound'>No Products Found.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
